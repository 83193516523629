
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { AssetModule, CampaignModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignCategoryParams } from '@/models/campaigns/campaignCategoryParams';
import { AssetsFilters, SortKey } from '@/models/assets/assetsFilters';
import { Region } from '@/models/stores/regions';
import { SocialChannel } from '@/models/posts/socialChannels';

const assetModule = getModule(AssetModule);
const campaignModule = getModule(CampaignModule);

interface SortKeySelection {
  id: SortKey;
  title: string;
}

@Component
export default class AssetFilters extends LoggedInComponentBase {
  @Prop({ default: false }) isCreateChild!: boolean;
  @Prop({ default: false }) isAssetFiltersSheetOpen!: boolean;

  public get isSheetOpen(): boolean {
    return this.isAssetFiltersSheetOpen;
  }

  public set isSheetOpen(newVal: boolean) {
    this.$emit('is-asset-filters-sheet-open-change', newVal);
  }

  public get selectedCategory(): CampaignCategory {
    return assetModule.assetsFilters.category;
  }

  public set selectedCategory(category: CampaignCategory) {
    // on asset lib page category change is handled via router
    if (!this.isCreateChild) {
      this.$router.push({
        name: 'assetlibrary.category',
        params: { categoryId: category.id! },
      });
      return;
    }

    // in create-asset / create-post flow we trigger manually
    assetModule.setCurrentFilter({ ...assetModule.assetsFilters, category });
    this.$emit('filterApplied');
  }

  public get selectedRegion(): Region {
    return assetModule.assetsFilters.region || Region.ALL;
  }

  public set selectedRegion(region: Region) {
    assetModule.setCurrentFilter({ ...assetModule.assetsFilters, region });
    this.$emit('filterApplied');
  }

  public get campaignCategories(): CampaignCategory[] {
    return campaignModule.campaignCategories;
  }

  public get assetsFilters(): AssetsFilters {
    return assetModule.assetsFilters;
  }

  public get categoryFilters(): CampaignCategory[] {
    const filters: CampaignCategory[] = [];

    // Add the static 'All' filter
    const params = new CampaignCategoryParams();
    params.id = 'all';
    params.title = 'All categories';
    const allFilter = new CampaignCategory(params);
    filters.push(allFilter);

    // Now add a filter for each campaign category
    this.campaignCategories.forEach((category) => {
      filters.push(category);
    });

    return filters;
  }

  public get regionFilters(): Region[] {
    return this.storesModule.regions;
  }

  public get sortSelections(): SortKeySelection[] {
    return [
      { id: 'DatePublishedDescending', title: 'recently added first' },
      { id: 'DatePublishedAscending', title: 'oldest' },
      /*{id: 'PopularityDescending', title: 'most popular'},
      {id: 'PopularityAscending', title: 'least popular'},*/
      { id: 'AlphabeticalAscending', title: 'name: a-z' },
      { id: 'AlphabeticalDescending', title: 'name: z-a' },
    ];
  }

  public get selectedSortKey(): SortKey {
    return assetModule.assetsFilters.sortKey || '';
  }

  public set selectedSortKey(sortKey: SortKey) {
    assetModule.setCurrentFilter({ ...assetModule.assetsFilters, sortKey });
    this.$emit('filterApplied');
  }

  public get selectedSocialChannel(): SocialChannel | undefined {
    return assetModule.assetsFilters.socialChannel;
  }

  public set selectedSocialChannel(socialChannel: SocialChannel | undefined) {
    assetModule.setCurrentFilter({
      ...assetModule.assetsFilters,
      socialChannel,
    });
    this.$emit('filterApplied');
  }
}
