
import { Component, Watch, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { getModule } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import { AssetModule, CampaignModule, CreateModule } from '@/store';

import AssetCard from '@/components/cards/assetCard.vue';
import VideoCard from '@/components/cards/videoCard.vue';
import CampaignSidebar from '@/components/campaign-sidebar.vue';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { SidebarMixin } from '@/mixins/sidebarMixin';
import AssetFilters from './assetFilters.vue';
import AssetShareModal from './assetShareModal.vue';
import StoreSwitcherMob from '@/components/storeSwitcherMob.vue';

// types
import { PagedAssetListResponse } from '@/api/contracts/assets';
import { Region } from '@/models/stores/regions';
import { CreateSubmitTypes } from '@/models/createSubmitTypes';
import { AssetListRequest } from '@/models/assets/assetListRequest';
import { AssetListItem } from '@/models/assets/assetListItem';

const assetModule = getModule(AssetModule);
const campaignModule = getModule(CampaignModule);
const createModule = getModule(CreateModule);

@Component({
  name: 'AssetLibraryIndex',
  components: {
    AssetCard,
    AssetFilters,
    AssetShareModal,
    CampaignSidebar,
    VideoCard,
    StoreSwitcherMob,
  },
  mixins: [SidebarMixin],
})
export default class AssetLibraryIndex extends LoggedInComponentBase {
  @Prop({ default: false }) isCreateChild!: boolean;
  public assetsPerPage: number = this.$vuetify.breakpoint.xs ? 20 : 21;
  public currentPage: number = 1;
  public search: string = '';
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public assetShareModalOpened: boolean = false;
  public sharingAsset: AssetListItem | null = null;
  public serviceClient = new AssetsServiceClient();
  public isAssetFiltersSheetOpen: boolean = false;
  public isHidingVideoAssets: boolean = false;
  public isCreateAssetSuccessVisible: boolean = false;
  public createAssetSuccessIntervalId: number = 0;
  public createAssetSuccessCountdown: number = 5;
  public isGettingAssets: boolean = false;
  public isInitialising: boolean = false;

  public onAssetShareModalOpened(asset: AssetListItem): void {
    this.sharingAsset = asset;
    this.assetShareModalOpened = true;
  }

  public get userGroups(): string[] {
    return this.userModule.currentUser?.storeGroups?.map((g) => g.name) || [];
  }

  public get assetsList(): Array<AssetListItem> {
    const assets = this.storeAssetsResponse.data.filter((asset) => {
      if (this.isHidingVideoAssets) {
        return !asset.isVideoAsset;
      }
      return true;
    });
    return assets;
  }

  public get currentStore() {
    return this.userModule.currentStore;
  }

  public get newAssetModalVisible(): boolean {
    return this.newAssetModalModule.visible;
  }

  public get showPagination() {
    return this.storeAssetsResponse.pagination.total > this.assetsPerPage;
  }

  public get storeAssetsResponse(): PagedAssetListResponse {
    return assetModule.storeAssetsResponse;
  }

  public get selectedGroupFilter(): string {
    return assetModule.selectedGroupFilter;
  }

  public set selectedGroupFilter(val) {
    assetModule.setSelectedGroupFilter(val);
  }

  public get shouldIncludeRecommended(): boolean {
    return this.selectedGroupFilter === 'national';
  }

  public get paginationLength(): number | null {
    if (!this.storeAssetsResponse?.pagination) {
      return 0;
    } else if (this.storeAssetsResponse.pagination.total <= 0) {
      return 0;
    } else {
      const assetsPerPage = this.assetsPerPage <= 0 ? 1 : this.assetsPerPage;
      return Math.ceil(
        this.storeAssetsResponse.pagination.total / assetsPerPage
      );
    }
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: AssetLibraryIndex) => void)) => void
  ): Promise<void> {
    try {
      await campaignModule.getCampaignCategories();

      assetModule.setCurrentFilter({
        category: {
          id: to.params.categoryId,
        },
      });
      next();
    } catch {
      next(false);
    }
  }

  async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: AssetLibraryIndex) => void)) => void
  ): Promise<void> {
    try {
      this.currentPage = 1;
      assetModule.setCurrentFilter({
        category: {
          id: to.params.categoryId,
        },
      });
      await this.getAssets();
      next();
    } catch {
      next(false);
    }
  }

  private async beforeRouteLeave(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: AssetLibraryIndex) => void)) => void
  ): Promise<void> {
    if (to.name?.toLowerCase() !== 'assetlibrary.root') {
      this.newAssetModalModule.setNewAssetModalVisible(false);
    }
    next();
  }

  private beforeDestroy() {
    this.$root.$off('open-asset-filters-sheet');
    clearInterval(this.createAssetSuccessIntervalId);
  }

  private async created() {
    this.isInitialising = true;

    // emitted @mainNavMob
    this.$root.$on('open-asset-filters-sheet', this.openAssetFiltersSheet);

    // clear all previous assets
    assetModule.setStoreAssetsResponse({
      pagination: { total: 0, limit: 0, start: 0, sort: '' },
      data: [],
    });

    if (this.$route.path === '/create-asset') {
      this.isHidingVideoAssets = true;
    }

    if (this.isCreateChild) {
      assetModule.setCurrentFilter({
        category: {
          id: 'all',
          title: 'All categories',
        },
      });

      await campaignModule.getCampaignCategories();
      if (!this.storesModule.regions.length) {
        await this.storesModule.getRegions();
      }
    }

    await this.checkSocialChannelsAuthorised();

    if (createModule.submitSuccess === CreateSubmitTypes.ASSET) {
      this.selectedGroupFilter = 'myAssets';
    }

    await this.getAssets();

    // watch group filter after initial setup
    this.$watch('selectedGroupFilter', this.onUserGroupChanged);

    // show /create-asset success feedback
    if (createModule.submitSuccess === CreateSubmitTypes.ASSET) {
      this.checkForNewlyCreatedAsset();
    }
  }

  public createNewAsset() {
    this.$router.push({ path: '/create-asset' });
  }

  private checkForNewlyCreatedAsset() {
    createModule.setSubmitSuccess(null);

    const isNewAssetReady = this.createAssetModule.newlyCreatedAssetIds.some(
      (newAssetId) => this.assetsList.find((asset) => asset.id === newAssetId)
    );

    if (isNewAssetReady) {
      this.notificationMessage = 'Asset has been successfully created.';
      this.showNotification = true;
      return;
    }

    // asset not yet ready so notify user
    this.createAssetSuccessCountdown = 5;
    this.isCreateAssetSuccessVisible = true;
    this.createAssetSuccessIntervalId = setInterval(() => {
      this.createAssetSuccessCountdown--;
      if (this.createAssetSuccessCountdown === 0) {
        clearInterval(this.createAssetSuccessIntervalId);
        this.getAssets();
      }
    }, 1000);
  }

  public async deleteAsset(asset: AssetListItem) {
    try {
      if (this.userModule.isViewingSingleStore) {
        await this.serviceClient.deleteAsset({
          assetId: asset.id,
          storeId: this.userModule.currentStore.id,
        });
      } else if (this.storesModule.currentRegion === Region.ALL) {
        // TODO: won't be caught by try{} catch{}
        await Promise.allSettled(
          asset.msoAssetStores!.map((store) =>
            this.serviceClient.deleteAsset({
              assetId: asset.id,
              storeId: store.storeId,
            })
          )
        );
      } else {
        // TODO: won't be caught by try{} catch{}
        await Promise.allSettled(
          asset
            .msoAssetStores!.filter(
              (store) => store.region === this.storesModule.currentRegion
            )
            .map((store) =>
              this.serviceClient.deleteAsset({
                assetId: asset.id,
                storeId: store.storeId,
              })
            )
        );
      }
      await this.getAssets();

      this.notificationMessage = 'Asset has been deleted';
      this.notificationColour = 'green';
      this.showNotification = true;
    } catch (err) {
      this.notificationMessage = 'There was an error deleting this asset.';
      this.showNotification = true;
      this.notificationColour = 'red';
    }
  }

  async getAssets(page: number = 0) {
    const campaignCategoryId = assetModule.assetsFilters.category.id || 'all';
    const start = page ? this.assetsPerPage * (page - 1) : 0;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    this.isGettingAssets = true;

    // clear new asset auto refresh on user action causing new request
    if (
      this.isCreateAssetSuccessVisible &&
      this.createAssetSuccessCountdown !== 0
    ) {
      clearInterval(this.createAssetSuccessIntervalId);
      this.isCreateAssetSuccessVisible = false;
    }

    await assetModule.getAssets(
      new AssetListRequest({
        campaignCategoryId,
        query: this.search,
        includeRecommended: this.shouldIncludeRecommended,
        pagination: {
          limit: this.assetsPerPage,
          start,
        },
        state: assetModule.assetsFilters.region,
        sort: assetModule.assetsFilters.sortKey,
        channel: assetModule.assetsFilters.socialChannel,
      })
    );

    this.isGettingAssets = false;
    this.isInitialising = false;

    if (this.isCreateAssetSuccessVisible) {
      this.isCreateAssetSuccessVisible = false;
    }
  }

  public openAssetFiltersSheet() {
    this.isAssetFiltersSheetOpen = true;
  }

  searchKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      this.getAssets();
    }
  }

  public async onFilterApplied() {
    this.currentPage = 1;
    this.getAssets();
  }

  @Watch('newAssetModalVisible')
  async onNewAssetModalVisibleChanged(visible: boolean) {
    if (!visible) {
      await this.getAssets();
    }
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    this.currentPage = 1;
    this.search = '';

    await this.getAssets();

    if (
      this.$route.name !== 'assetlibrary.root' &&
      this.$route.name !== 'assetlibrary.myassets'
    ) {
      await this.$router.push({
        name: 'assetlibrary.root',
      });
    }
  }

  public async onUserGroupChanged(newValue: string, oldValue: string) {
    if (newValue === oldValue) return;
    this.currentPage = 1;
    this.search = '';
    switch (newValue) {
      case 'national':
        assetModule.setCurrentFilter({
          ...assetModule.assetsFilters,
          region: Region.ALL,
        });
        break;

      case 'myAssets':
      default:
        break;
    }

    this.onFilterApplied();
  }
}
