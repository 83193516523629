import { SocialChannel } from '@/models/posts/socialChannels';

export enum CreateSubmitTypes {
  ASSET = 'asset',
  POST = 'post'
}

export enum CreateSubmitStatus {
  PENDING = 'pending',
  FAIL = 'fail',
  SUCCESS = 'success'
}

export interface AssetSubmitFeedback {
  storeTitle: string;
  status: CreateSubmitStatus;
  displayError?: string;
  exception?: string;
  errorHelpLink?: string;
}

export interface PostSubmitFeedback extends AssetSubmitFeedback {
  channels: SocialChannel[];
}

export interface AssetSubmitFeedbackMap {
  [storeId: string]: AssetSubmitFeedback;
}

export interface PostSubmitFeedbackMap {
  [storeId: string]: PostSubmitFeedback;
}
